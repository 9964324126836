<div id="login" fxLayout="column">

    <div id="login-form-wrapper" >

        <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
            <div class="title">LOGIN TO YOUR ACCOUNT</div>

            <form name="loginForm" [formGroup]="loginForm" novalidate
                  (ngSubmit)="ngOnSubmit(inputEmail.value,inputPassword.value)">

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input #inputEmail matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="loginForm.get('email').hasError('required')">
                        Email is required
                    </mat-error>
                    <mat-error
                        *ngIf="!loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input #inputPassword matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        Password is required
                    </mat-error>
                </mat-form-field>

                <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="space-between center">

                    <a class="forgot-password" [routerLink]="'/pages/auth/forget-password'">
                        Forgot Password?
                    </a>
                </div>

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                        [disabled]="loginForm.invalid || callService">
                    LOGIN
                </button>

            </form>

        </div>

    </div>

</div>
