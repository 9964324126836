import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorIntermediatorHandlerService {
    errorSubject = new Subject<any>();

  constructor() { }
    setError(error): void {
        this.errorSubject.next(error);
    }
}
