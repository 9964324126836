<div id="view-fax" fxLayout="column">

    <div id="view-fax-form-wrapper">

        <div id="view-fax-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">


            <form name="viewFaxForm" [formGroup]="form" novalidate>
                <div class="title">VIEW FAX</div>

                <mat-form-field appearance="outline">
                    <mat-label>Your Fax Number</mat-label>
                    <input matInput formControlName="recipient"
                           required>
                    <mat-error *ngIf="form.get('recipient').hasError('required')">
                        Fax Number is required
                    </mat-error>
                    <mat-error
                        *ngIf="!form.get('recipient').hasError('required') && form.get('recipient').errors?.pattern">
                        Please, Enter valid Fax Number
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Access Code</mat-label>
                    <input matInput formControlName="accessCode" maxlength="30"
                           required>
                    <mat-error>
                        Access Code is required
                    </mat-error>
                </mat-form-field>

                <div class="signup" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="space-between center">
                    <mat-checkbox (change)="onChange($event)" class="text"
                                  aria-label="SignUp">
                        SignUp
                    </mat-checkbox>
                </div>

                <div *ngIf="isSignUp" [formGroup]="accountRegistration">
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email" (keydown)="isAccountAlreadyRegister=false; return;"
                               (blur)="isAccountRegistered()">
                        <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                        <mat-error *ngIf="accountRegistration.get('email').hasError('required')">
                            Email is required
                        </mat-error>
                        <mat-error
                            *ngIf="!accountRegistration.get('email').hasError('required') && accountRegistration.get('email').hasError('email')">
                            Please enter a valid email address
                        </mat-error>
                    </mat-form-field>
                    <small *ngIf="accountRegistration.controls['email'].valid && isAccountAlreadyRegister">* Note
                        that this
                        email is already registered, so you've to enter your password</small>

                    <mat-form-field appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" formControlName="password">
                        <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                        <mat-error *ngIf="accountRegistration.get('password').hasError('required')">
                            Password is required
                        </mat-error>
                        <mat-error *ngIf="accountRegistration.get('password').hasError('pattern')">
                            <small> Password must match conditions: At least one digit [0-9],At least one lowercase
                                character [a-z],At least one uppercase character [A-Z] ,At least one special character
                                [*.!@#$%^&()[]:;<>,.?/~_+-=|\\] </small>
                        </mat-error>

                    </mat-form-field>
                    <div *ngIf="accountRegistration.get('password').hasError('pattern')" style="height: 20px"></div>
                    <div *ngIf="!isAccountAlreadyRegister" [formGroup]="userInfo">

                        <mat-form-field appearance="outline">
                            <mat-label>First Name</mat-label>
                            <input matInput formControlName="fname" required>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error>
                                First Name is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Last Name</mat-label>
                            <input matInput formControlName="lname" required>
                            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                            <mat-error>
                                Last Name is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="!isSignUp else elseBlock">
                    <button mat-raised-button class="submit-button" color="accent"
                            aria-label="View Fax" [disabled]="form.invalid" (click)="viewFax()">
                        View Fax
                    </button>
                </div>
                <ng-template #elseBlock>
                    <button *ngIf="isAccountAlreadyRegister" mat-raised-button class="submit-button" color="accent"
                            aria-label="Update Account"
                            [disabled]="accountRegistration.invalid || form.invalid || callService"
                            (click)="updateAccountByFax()">
                        Update Account
                    </button>
                    <button *ngIf="!isAccountAlreadyRegister" mat-raised-button class="submit-button" color="accent"
                            aria-label="SignUp"
                            [disabled]="accountRegistration.invalid || form.invalid || userInfo.invalid || callService "
                            (click)="registerAccountByFax()">
                        SignUp
                    </button>
                </ng-template>
            </form>

        </div>

    </div>

</div>
