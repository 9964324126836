import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class FileManagementService {
    _arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    downloadFromByteArray(fileName, buffer) {
        const base64String = this._arrayBufferToBase64(buffer);
        this.downloadFromByteBase64(fileName, base64String);
    }

    downloadFromByteBase64(fileName, buffer) {
        const exten: string = fileName.substr(fileName.lastIndexOf('.') + 1);
        const linkSource = 'data:application/' + exten + ';base64,' + buffer;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }


}