import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {Subject} from 'rxjs';

const viewFaxQuery = gql`query viewFax($accessCode:String! $recipient:String!){
    viewFax(accessCode: $accessCode recipient: $recipient)
}`;

@Injectable({
    providedIn: 'root'
})
export class FaxService {

    viewFaxSubject = new Subject<any>();

    constructor(private apollo: Apollo) {
    }

    viewFax(accessCode: string, recipient: string): void {
        this.apollo.query<any>({
            query: viewFaxQuery, variables: {
                accessCode: accessCode,
                recipient: recipient
            }, fetchPolicy: 'cache-first'
        }).subscribe(({data}) => {
            this.viewFaxSubject.next(data.viewFax);
        });
    }
}
