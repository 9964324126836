import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

import {FuseModule} from '@fuse/fuse.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';

import {fuseConfig} from 'app/fuse-config';

import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {GraphQLModule} from './graphql.module';
import {CookieService} from 'ngx-cookie-service';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {GlobalErrorHandlerService} from './Error-handler/global-error-handler.service';
import {LoginModule} from './main/pages/authentication/login/login.module';
import {HomeModule} from './main/pages/home/home.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {RefreshTokenService} from "./main/apps/mail/service/refreshToken";

const appRoutes: Routes = [
    {
        path: 'apps',
        loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule)
    },
    {
        path: 'pages',
        loadChildren: () => import('./pages.module').then(m => m.PagesModule)
    },
    { path: '',   redirectTo: '/login', pathMatch: 'full' },
    {
        path      : '**',
        loadChildren: () => import('./main/pages/home/home.module').then(m => m.HomeModule)
    },



];

@NgModule({
    declarations: [
        AppComponent

    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        // FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        // AppStoreModule,
        MatFormFieldModule,
        MatStepperModule,
        MatInputModule,
        MatCheckboxModule,
        GraphQLModule,
        InfiniteScrollModule,
        LoginModule,
        HomeModule,
        MatSnackBarModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [ { provide: ErrorHandler, useClass: GlobalErrorHandlerService } , CookieService,RefreshTokenService ]
})
export class AppModule {
}
