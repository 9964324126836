import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';


import {MatCheckboxModule} from '@angular/material/checkbox';
import {ViewFaxAccessCodeComponent} from './view-fax-accessCode.component';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';

const routes = [
    {
        path     : 'auth/fax-accessCode',
        component: ViewFaxAccessCodeComponent
    }
];

@NgModule({
    declarations: [
        ViewFaxAccessCodeComponent
    ],
    exports: [
        ViewFaxAccessCodeComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        FuseSharedModule,
        MatCheckboxModule
    ]
})
export class ViewFaxAccessCodeModule
{
}
