import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class RefreshTokenService {

    onRefreshToken = new Subject<any>();
    onResetUnixTime = new Subject<any>();


    refreshToken(token) {
        this.onRefreshToken.next(token);
    }

    resetExpirationTime(unixTime) {
        this.onResetUnixTime.next(unixTime);
    }
}