import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';

import {HomeComponent} from './home.component';
import {LoginModule} from '../authentication/login/login.module';
import {ViewPdfModule} from '../view-pdf/view-pdf.module';
import {ViewFaxAccessCodeModule} from '../fax/view-fax-accessCode/view-fax-accessCode.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FuseSharedModule} from '../../../../@fuse/shared.module';

const routes = [
    {

        path: 'login',
        component: HomeComponent
    }

];

@NgModule({
    declarations: [
        HomeComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatIconModule,

        FuseSharedModule,
        LoginModule,
        ViewPdfModule,
        ViewFaxAccessCodeModule,
        MatFormFieldModule
    ]
})
export class HomeModule
{
}
