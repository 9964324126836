import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';


import {ViewPdfComponent} from './view-pdf.component';
import {MatMenuModule} from '@angular/material/menu';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {FuseSharedModule} from "../../../../@fuse/shared.module";

const routes = [
    {
        path     : 'auth/view-pdf',
        component: ViewPdfComponent
    }
];

@NgModule({
    declarations: [
        ViewPdfComponent
    ],
    exports: [
        ViewPdfComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        FuseSharedModule,
        MatMenuModule,
        NgxExtendedPdfViewerModule
    ]
})
export class ViewPdfModule
{
}
