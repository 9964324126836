import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Apollo, gql} from 'apollo-angular';
import {Account} from '../../../model/account';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {RefreshTokenService} from "./refreshToken";

const AddFaxNumberToAccount = gql` mutation addFaxToRegisteredAccount($faxNumber: String $accessCode: String)
{ addFaxToRegisteredAccount(faxNumber:$faxNumber accessCode: $accessCode ) }`;
const refreshToken = gql`
    query refreshToken
    {
        refreshToken{
            token
            fname
            lname
            unixTime
            faxStatus
            fax
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    onFaxNumberIsAdded = new Subject<any>();
    account: Account;
    loginSubject = new BehaviorSubject<any>(null);
    refreshTokenSubject = new Subject<any>();

    constructor(private apollo: Apollo,
                private cookieService: CookieService,
                private router: Router,
                private refreshTokenService: RefreshTokenService) {

        this.refreshTokenService.onRefreshToken.subscribe((data) => {
            this.account.token = data;
            this.setCookieWithAccount();
        })
        this.refreshTokenService.onResetUnixTime.subscribe((data => {
            this.account.unixTime = data;
            this.setCookieWithAccount();
        }))

    }

    AddFaxNumberToAccount(faxNumber, accessCode): void {
        this.apollo.mutate({
            mutation: AddFaxNumberToAccount, variables: {
                faxNumber: faxNumber,
                accessCode: accessCode
            },
            context: {
                headers: {
                    Authorization: 'Bearer ' + this.account.token
                },
            }
        }).subscribe(({data}) => {
            // update cookie with new account
            this.onFaxNumberIsAdded.next(data.toString());
        });
    }

    refreshUserData(): void {
        this.apollo.query<any>({
            query: refreshToken, context: {
                headers: {
                    Authorization: 'Bearer ' + this.account.token
                },
            }
        })
            .subscribe(({data}) => {
                this.account = data.refreshToken as Account;
                this.cookieService.set('accountCookie', JSON.stringify(this.account), 1, '/', '', false, 'Strict');
                this.loginSubject.next(this.account);
                this.refreshTokenSubject.next('token refreshed');

            }, () => {
                this.account = null;
            });

    }

    autoLogIn(): void {
        if (this.cookieService.get('accountCookie')) {
            this.account = JSON.parse(this.cookieService.get('accountCookie'));
            console.log('Date.now() is : ' + new Date().getTime() + ' and account time is: ' + this.account.unixTime + ' ' + (new Date().getTime() < this.account.unixTime));
            if (this.account) {
                if (new Date().getTime() > this.account.unixTime) {
                    this.account = null;
                } else {
                    this.loginSubject.next(this.account);
                }
            }
        } else {
            this.router.navigateByUrl('/');
        }
    }

    logOut(): void {
        console.log('Log Out.......');
        this.account = null;
        this.cookieService.deleteAll('/');
        this.router.navigateByUrl('/');
    }

    setCookieWithAccount() {
        this.cookieService.set('accountCookie', JSON.stringify(this.account), 1, '/', '', false, 'Strict');
    }

}
