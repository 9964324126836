import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {AccountManagementService} from '../../service/account-management.service';
import {Router} from '@angular/router';
import {ErrorIntermediatorHandlerService} from '../../../../Error-handler/error-intermediator-handler.service';
import {FuseProgressBarService} from '../../../../../@fuse/components/progress-bar/progress-bar.service';
import {Subscription} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    callService = false;

    errorSubscription: Subscription;
    loginSubscription: Subscription;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _accountService: AccountManagementService,
        private _errorService: ErrorIntermediatorHandlerService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _cookieService: CookieService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        this.errorSubscription = this._errorService.errorSubject.subscribe(errorCode => {
            this.callService = false;
            this._fuseProgressBarService.hide();
        });

        this.loginSubscription = this._accountService.loginSubject.subscribe((account) => {
            this._cookieService.set('accountCookie', JSON.stringify(account), 1, '/', '', false, 'Strict');
            this._router.navigate(['apps/message/mail/inbox']);
        });


    }

    ngOnSubmit(emailValue: string, passwordValue: string): void {
        this.callService = true;
        this._fuseProgressBarService.show();
        this._accountService.login(emailValue, passwordValue);
    }

    ngOnDestroy(): void {
        this.errorSubscription.unsubscribe();
        this.loginSubscription.unsubscribe();
    }
}


