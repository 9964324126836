<div id="home" style="display: flex;flex-direction:column;flex-flow: wrap;">


    <div style="width: 50%;height: 100%;background:  url(/assets/images/backgrounds/background_nature.jpeg) no-repeat;
    background-size: cover;min-width: 500px;display: flex;flex-direction:column">

        <div class="logo" style="align-self: flex-start;">
            <div style="display: flex;align-items: center;  justify-content: center;height: 67px;">

            </div>

        </div>
 
        <div style="align-self:center;">
            <login></login>
            <div style="width: 100%;display: flex;align-items: center;  justify-content: center;margin-top: 10px;">
                <div style="
            background-color: #5e4185;color: #FFF;padding: 15px;">
                    DAK.MD
                </div>
            </div>
        </div>
    </div>
    <div style="width: 50%;height: 100%;background-color: #f1f1f1;min-width: 500px;display: flex;flex-direction:column">
        <div class="logo" style="align-self: flex-start;margin-top: 20px;margin-bottom: 48px;">
            <div style="display: flex;align-items: center;  justify-content: center;height: 100%;">
                <a href="https://allegiancemd.com/view-demo" target="_blank" style="text-decoration: none;">
                    <img style="width: 30%;  display: block;
                    margin-left: auto;
                    margin-right: auto;" src="../../../../assets/images/logos/dak_logo_black.png">
                    <div style="color: #455056;margin-top: 5px;">Secure HIPAA Messaging Portal By AllegianceMD</div>
                        <div style="color: #455056;margin-top: 5px;text-align: center;"> View AllegianceMD EHR/PM Demo</div>
                </a>
            </div>
        </div>
        <div style="align-self: center">
            <view-fax-accesscode></view-fax-accesscode>
        </div>

        
        <div style="width: 100%;display: flex;align-items: center;  justify-content: center;">
            <div style="width: 120px;">
                <img src="../../../../assets/images/logos/hipaa.png" />
            </div>
    </div>
    <div style="
    align-self: center;
    margin-top: 20px;
   ">Dak: transport or post by relays of men and horses (https://www.merriam-webster.com/)</div>
           </div>
</div>