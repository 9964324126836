import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, ApolloLink, DefaultOptions, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {onError} from '@apollo/client/link/error';
import {RefreshTokenService} from './main/apps/mail/service/refreshToken';


const uri = 'https://dak.md/graphql'; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink, refreshTokenService: RefreshTokenService): ApolloClientOptions<any> {



    const errorLink = onError(({graphQLErrors, networkError}) => {

        if (graphQLErrors) {
            graphQLErrors.map(({message, locations, path}) =>
                console.error(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                ),
            );
            graphQLErrors.forEach(i => {
                throw i;
            });
        }
        if (networkError) {
            console.error(`[Network error]: ${networkError}`);
            // @ts-ignore
            if (`${networkError.status}` === '401') {
                window.location.href = '/';
            } else {
                window.location.href = '/pages/errors/network-error';
            }
        }
    });

    // After the backend responds, we take the refreshToken from headers if it exists, and save it in the cookie.
    const afterWareLink = new ApolloLink((operation, forward) => {

        return forward(operation).map(response => {
            const context = operation.getContext();
            const {
                response: {headers}
            } = context;

            if (headers !== null) {

                const refreshToken = headers.get('refreshed-token');
                const unixTime = headers.get('unix-time');
                if (refreshToken !== null && refreshToken.length > 0) {
                    refreshTokenService.refreshToken(refreshToken);
                }

                if (unixTime !== null && unixTime.length > 0) {
                    refreshTokenService.resetExpirationTime(unixTime);
                }
            }

            return response;
        });
    });


    const httpLinkWithErrorHandling = ApolloLink.from([
        errorLink,

        afterWareLink,
        httpLink.create({
            uri
        })
    ]);

    const defaultOptions: DefaultOptions = {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
    };


    return {
        link: httpLinkWithErrorHandling,
        cache: new InMemoryCache(),
        defaultOptions: defaultOptions,
    };
}

@NgModule({
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink, RefreshTokenService]
        }
    ],
})
export class GraphQLModule {

}
