// Errors which will need showed at the same component
export const errorMessagesMap = new Map([
    ['ACCOUNT_NOT_ACTIVE', 'The account is not activated, Please activate your account'],
    ['ACCOUNT_SUSPENDED', 'The account is suspended, Please try again later'],
    ['MESSAGE_ID_EXPIRED', 'Message is expired'],
    ['PASS_NOT_VALID_FORMAT', 'Password must match conditions:<ul><li>At least one digit </li><li>At least one lowercase character</li><li>At least one uppercase character</li><li>At least one special character</li></ul> '],
    ['BAD_REQUEST', 'Invalid Inputs'],
    ['INTERNAL_SERVER_ERROR', 'Error with performing the process, please try again later, and if the issue still exists contact our support'],
    ['SERVER_UNAVAILABLE', 'Server Unavailable now ,Please try again later.'],
    ['NO_FAX_NUMBER', 'You have not registered with this faxNumber.'],
    ['OTP_EXPIRED', 'OTP is expired, please request another one.'],
    ['INVALID_OTP', 'OTP is invalid please request another one.'],
    ['WRONG_USERNAME_OR_PASSWORD', 'Wrong username or password.'],
    ['ACCOUNT_IS_ALREADY_VERIFIED', 'Account already verified'],
    ['ACCOUNT_NOT_FOUND', 'Email Not Found'],
    ['LINK_ALREADY_USED', 'Invalid Link please request new request password email'],
    ['EXPIRED_FAX_DURATION', 'This Fax Is No Longer Available.'],
    ['IN_ACTIVE_MESSAGE', 'This message is no longer available.']
]);

// Errors which will need navigation action
export const errorMessagesNavMap = new Map([
    ['MESSAGE_ID_EXPIRED', 'Message is expired cannot view it'],
    ['ILLEGAL_REQUEST', 'ILLEGAL REQUEST']
]);


