<ngx-extended-pdf-viewer *ngIf="pdfBytes else elseBlock"
                         [base64Src]="pdfBytes"
                         useBrowserLocale="false"
                         style="height: 100%; width: 100%"
                         [showHandToolButton]="true"
                         [showPrintButton]="true"
                         [handTool]=false
                         [showBookmarkButton]="false"
                         [showOpenFileButton]="false"                          
>
</ngx-extended-pdf-viewer>
<ng-template #elseBlock>
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex>
        <mat-icon class="s-128 mb-16 select-message-icon hint-text"
                  [@animate]="{value:'*',params:{delay:'300ms',scale:'0.2'}}">
            email
        </mat-icon>
        <span class="select-message-text hint-text" [@animate]="{value:'*',params:{delay:'400ms'}}">
        <span>You have no permission to view Fax.</span>
    </span>
    </div>
</ng-template>