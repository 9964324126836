import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Router} from '@angular/router';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {FaxService} from '../../service/fax.service';
import {FuseProgressBarService} from '../../../../../@fuse/components/progress-bar/progress-bar.service';
import {AccountManagementService} from '../../service/account-management.service';
import {Account} from '../../../model/account';
import {MatSnackBar} from '@angular/material/snack-bar';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {FuseConfigService} from '../../../../../@fuse/services/config.service';
import {ErrorIntermediatorHandlerService} from '../../../../Error-handler/error-intermediator-handler.service';
import {PdfViewerService} from '../../service/pdf-viewer.service';
import {Subscription} from 'rxjs';
import {FileManagementService} from "../../../utils/file-management-service";

@Component({
    selector: 'view-fax-accesscode',
    templateUrl: './view-fax-accessCode.component.html',
    styleUrls: ['./view-fax-accessCode.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ViewFaxAccessCodeComponent implements OnInit, OnDestroy {

    form: FormGroup;
    accountRegistration: FormGroup;
    userInfo: FormGroup;

    account: Account;
    isSignUp: boolean;
    isAccountAlreadyRegister = false;
    callService = false;

    confirmationMessage: string;
    pwdPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&].{8,}';


    viewFaxSubscription: Subscription;
    registerAccountByFaxSubscription: Subscription;
    updateAccountByFaxSubscription: Subscription;
    isRegisteredAccountByEmailSubscription: Subscription;
    errorSubscription: Subscription;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _faxService: FaxService,
        private _accountService: AccountManagementService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _errorService: ErrorIntermediatorHandlerService,
        private _snackBar: MatSnackBar,
        private _pdfViewerService: PdfViewerService,
        private fileManagementService: FileManagementService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

    }


    /**
     * On init
     */
    ngOnInit(): void {

        this.form = this._formBuilder.group({
            accessCode: ['', [Validators.required, Validators.maxLength(30), Validators.minLength(6)]],
            recipient: ['', [Validators.required, Validators.pattern('(^1[0-9]{10}$)|(^[2-9][0-9]{9}$)')]]
        });

        this.accountRegistration = this._formBuilder.group({
            password: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]]
        });
        this.userInfo = this._formBuilder.group({
            fname: ['', Validators.required],
            lname: ['', Validators.required]
        });

        this.userInfo.disable();

        this.viewFaxSubscription = this._faxService.viewFaxSubject.subscribe(data => {
            this._fuseProgressBarService.hide();
            this._pdfViewerService.pdfBytesArray = data;
            this._router.navigateByUrl('/pages/auth/view-pdf');
        });

        this.registerAccountByFaxSubscription = this._accountService.registerAccountByFaxSubject.subscribe(() => {
            this._fuseProgressBarService.hide();
            this.goToRegisterConfirm();
        });
        this.updateAccountByFaxSubscription = this._accountService.updateAccountByFaxSubject.subscribe(() => {
            this._fuseProgressBarService.hide();
            this.goToRegisterConfirm();
        });

        this.isRegisteredAccountByEmailSubscription = this._accountService.isRegisteredAccountByEmailSubject.subscribe(isRegister => {
            this.isAccountAlreadyRegister = isRegister;
            this._fuseProgressBarService.hide();
            this.accountRegistration.get('password').reset();

            if (isRegister) {
                this.accountRegistration.get('password').setValidators(Validators.required);
            } else {
                this.userInfo.enable();
                this.accountRegistration.get('password').setValidators([Validators.required, Validators.pattern(this.pwdPattern)]);
            }
        });

        this.errorSubscription = this._errorService.errorSubject.subscribe(() => {
            this.callService = false;
        });


    }

    viewFax(): void {
        this._fuseProgressBarService.show();
        this._snackBar.dismiss();
        this._faxService.viewFax(this.form.value.accessCode, this.form.value.recipient);

    }


    onChange($event: MatCheckboxChange): void {
        this.isSignUp = $event.checked;
        this.callService = false;
    }


    getAccountInformation(): void {
        this.account = {
            fname: this.userInfo.value.fname,
            lname: this.userInfo.value.lname,
            password: this.accountRegistration.value.password,
            email: this.accountRegistration.value.email,
            fax: this.form.value.recipient
        };
    }

    registerAccountByFax(): void {
        this._snackBar.dismiss();
        this.callService = true;
        this._fuseProgressBarService.show();
        this.getAccountInformation();
        this._accountService.RegisterAccountByFax(this.account, this.form.value.accessCode);

    }

    updateAccountByFax(): void {
        this._snackBar.dismiss();
        this.callService = true;
        this._fuseProgressBarService.show();
        this.getAccountInformation();
        this._accountService.UpdateAccountByFax(this.account, this.form.value.accessCode);
    }

    goToRegisterConfirm(): void {
        if (this.isAccountAlreadyRegister) {
            this.confirmationMessage = 'your account is updated successfully';
        } else {
            this.confirmationMessage = 'an email sent to you to verify your account.';
        }
        this._router.navigateByUrl('/pages/auth/register-confirm', {
            state: {
                message: this.confirmationMessage
            }
        });
    }

    isAccountRegistered(): void {
        if (this.accountRegistration.controls['email'].valid) {
            this._fuseProgressBarService.show();
            this.userInfo.disable();
            this._accountService.isAccountRegister(this.accountRegistration.value.email);
        }
    }

    ngOnDestroy(): void {
        this.viewFaxSubscription.unsubscribe();
        this.registerAccountByFaxSubscription.unsubscribe();
        this.updateAccountByFaxSubscription.unsubscribe();
        this.isRegisteredAccountByEmailSubscription.unsubscribe();
        this.errorSubscription.unsubscribe();
    }

}
