import {Injectable} from '@angular/core';
import gql from 'graphql-tag';
import {Apollo} from 'apollo-angular';
import {Subject} from 'rxjs';
import {Account} from '../../model/account';

const isRegisteredByMessageIdQuery = gql`query isRegisteredByMessageId($messageId:String!){
    isRegisteredByMessageId(messageId: $messageId)
}`;

const SignUpByEmailMutation = gql` mutation createAccountByEmail($account: AccountInput $oneTimePassword: Int $msgId: String)
{ createAccountByEmail(account:$account  oneTimePassword: $oneTimePassword msgId:$msgId) }`;

const SignUpByFaxMutation = gql` mutation createAccountByFax($account: AccountInput $accessCode: String)
{ createAccountByFax(account:$account  accessCode: $accessCode ) }`;

const LoginQuery = gql`
    query login($username: String! $password:String!)
    {
        login(
            username: $username
            password: $password
        ){
            token
            fname
            lname
            unixTime
            faxStatus
            fax
        }
    }
`;

const ForgetPasswordQuery = gql`
    query forgetPassword($username: String!)
    {
        forgetPassword (username: $username)
    }
`;

const ResetPasswordQuery = gql`
    mutation resetPassword($hashcode: String! $password:String! $forgetPasswordId: String!)
    {
        resetPassword(
            hashcode: $hashcode
            password: $password
            forgetPasswordId: $forgetPasswordId)
    }
`;

const isRegisteredByEmailQuery = gql`
    query isRegisteredByEmail($email: String! )
    {
        isRegisteredByEmail(email:$email)
    }
`;

const AccountVerificationMutation = gql`
    mutation verifyAccount($tempAccountId: String! )
    {
        verifyAccount(tempAccountId:$tempAccountId)
    }
`;

const UpdateAccountByFaxMutation = gql` mutation updateAccountByFax($account: AccountInput $accessCode: String)
{ updateAccountByFax(account:$account  accessCode: $accessCode ) }`;


@Injectable({
    providedIn: 'root'
})

export class AccountManagementService {
    account: Account;

    registerAccountByEmailSubject = new Subject<any>();

    registerAccountByFaxSubject = new Subject<any>();

    forgetPasswordSubject = new Subject<any>();

    resetPasswordSubject = new Subject<any>();

    accountVerificationSubject = new Subject<any>();

    updateAccountByFaxSubject = new Subject<any>();

    isRegisteredAccountByMessageIdSubject = new Subject<boolean>();

    isRegisteredAccountByEmailSubject = new Subject<boolean>();

    loginSubject = new Subject<Account>();


    constructor(private apollo: Apollo) {}

    RegisterAccountByEmail(account, msgId, otp): void {
        this.apollo.mutate({
            mutation: SignUpByEmailMutation,
            variables: {
                account: account,
                msgId: msgId,
                oneTimePassword: otp
            }
        }).subscribe(({data}) => {
            this.registerAccountByEmailSubject.next(data.toString());
        });
    }

    RegisterAccountByFax(account, accessCode): void {
        this.apollo.mutate({
            mutation: SignUpByFaxMutation, variables: {
                account: account,
                accessCode: accessCode
            }
        }).subscribe(({data}) => {
            this.registerAccountByFaxSubject.next(data.toString());
        });
    }

    login(email, password): void {
        this.apollo.query<any>({
            query: LoginQuery,
            variables: {
                username: email,
                password: password
            }
        })
            .subscribe(({data}) => {
                this.account = data.login as Account;
                this.loginSubject.next(this.account);
            }, () => {
                this.account = null;
            });
    }

    forgetPassword(email): void {
        this.apollo.query<any>({
            query: ForgetPasswordQuery,
            variables: {
                username: email
            }
        })
            .subscribe(({data}) => {
                this.forgetPasswordSubject.next(data.forgetPassword.toString());
            });
    }

    ResetPassword(hashcode, password, forgetPasswordId): void {
        this.apollo.mutate<any>({
            mutation: ResetPasswordQuery,
            variables: {
                hashcode: hashcode,
                password: password,
                forgetPasswordId: forgetPasswordId
            },
        })

            .subscribe(({data}) => {
                this.resetPasswordSubject.next(data.resetPassword.toString);
            });
    }

    isRegistered(msgId): void {
        this.apollo.query<any>({
            query: isRegisteredByMessageIdQuery, variables: {
                messageId: msgId
            }, fetchPolicy: 'cache-first'
        }).subscribe(({data}) => {
            this.isRegisteredAccountByMessageIdSubject.next(data.isRegisteredByMessageId);
        });
    }

    VerifyAccount(tempAccountId): void {
        this.apollo.mutate<any>({
            mutation: AccountVerificationMutation,
            variables: {
                tempAccountId: tempAccountId
            },
        })
            .subscribe(({data}) => {
                this.accountVerificationSubject.next(data.verifyAccount.toString());
            });
    }

    isAccountRegister(email): void {
        this.apollo.query<any>({
            query: isRegisteredByEmailQuery,
            variables: {
                email: email
            },
        })
            .subscribe(({data}) => {
                this.isRegisteredAccountByEmailSubject.next(data.isRegisteredByEmail);
            });
    }

    UpdateAccountByFax(account, accessCode): void {
        this.apollo.mutate({
            mutation: UpdateAccountByFaxMutation, variables: {
                account: account,
                accessCode: accessCode
            }
        }).subscribe(({data}) => {
            this.updateAccountByFaxSubject.next(data.toString());
        });
    }

}
