import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {ErrorIntermediatorHandlerService} from './error-intermediator-handler.service';
import {errorMessagesMap, errorMessagesNavMap} from './error-messages';

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

    constructor(private ngZone: NgZone, private router: Router, private errorService: ErrorIntermediatorHandlerService) {
    }

    handleError(error): void {
        // console.error('An globaaal errorrr occurred:', error.message);
        // console.error('An globaaal errorrr code', error.code);
        // console.error('An globaaal errorrr status', error.status);
        if (error?.status === 0) {
            error.code = 'SERVER_UNAVAILABLE';
        }
        if (errorMessagesNavMap.has(error.code)) {
            this.ngZone.run(() => this.router.navigateByUrl('/pages/auth/error', {
                state: {
                    message: error.message,
                    code: error.code
                }
            }));
        } else if (errorMessagesMap.has(error.code)) {
            this.ngZone.run(() => {
                this.errorService.setError(error.code);
            });
        }
    }


}

