import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {errorMessagesMap} from '../../../Error-handler/error-messages';
import {ErrorIntermediatorHandlerService} from '../../../Error-handler/error-intermediator-handler.service';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, OnDestroy {

    errorMessage: string;
    errorSubscription: Subscription;

    constructor(private errorService: ErrorIntermediatorHandlerService,
                private _fuseProgressBarService: FuseProgressBarService,
                private _snackBar: MatSnackBar) {
    }

    ngOnInit(): void {
        // todo : to be implemented
        this.errorSubscription = this.errorService.errorSubject.subscribe(errorCode => {

            this._fuseProgressBarService.hide();

            this.errorMessage = errorMessagesMap.get(errorCode);

            this._snackBar.open(this.errorMessage, 'Close');
        });
    }

    ngOnDestroy(): void {
        this.errorSubscription.unsubscribe();
    }

}
