<mat-toolbar class="p-0 mat-elevation-z1" >

    <div fxFlex  fxLayout="row" fxLayoutAlign="start center" class="primary">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">


            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>
            <div class="logo" style="padding: 20px 20px 10px 10px;width: 160px;">
                <a href="https://allegiancemd.com/view-demo" target="_blank">
                  <img src="/assets/images/logos/logo.svg">
                </a>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">

                <div class="logo ml-16">
                    <a href="https://allegiancemd.com/view-demo" target="_blank">
                        <img src="/assets/images/logos/logo_orignal.svg">
                      </a>
                </div>
            </div>


        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-button [matMenuTriggerFor]="userMenu"
                    class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/avatar2.jpg">
                    <span class="username mr-12" fxHide fxShow.gt-sm> {{account?.fname}} {{account?.lname}}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item class="" (click)="logOut()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

            </mat-menu>


        </div>

    </div>

</mat-toolbar>
