import {Component, OnInit, ViewEncapsulation} from '@angular/core';


import {FuseConfigService} from '../../../../@fuse/services/config.service';
import {PdfViewerService} from '../service/pdf-viewer.service';
import {fuseAnimations} from '../../../../@fuse/animations';
import {FileManagementService} from '../../utils/file-management-service';


@Component({
    selector: 'view-pdf',
    templateUrl: './view-pdf.component.html',
    styleUrls: ['./view-pdf.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ViewPdfComponent implements OnInit {
    pdfBytes: string;

    /**
     * On init
     */
    ngOnInit(): void {
        this.pdfBytes = this._fileManagementService._arrayBufferToBase64(this.pdfViewerService.pdfBytesArray);
    }

    constructor(
        private pdfViewerService: PdfViewerService,
        private _fuseConfigService: FuseConfigService,
        private _fileManagementService: FileManagementService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // tslint:disable-next-line:typedef
    download() {
        this._fileManagementService.downloadFromByteBase64('document.pdf', this.pdfBytes);
    }
}
